import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AssessmentComponent } from './assessment/assessment.component'
import { LoginComponent } from './login/login.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { AdminComponent } from './admin/admin.component'
import { QuestionBuilderComponent } from './question-builder/question-builder.component'
import { UsersComponent } from './users/users.component'
import { PatientsComponent } from './patients/patients.component';

const routes: Routes = [
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'assessment', component: AssessmentComponent },
  { path: 'assessmentlist', component: AdminComponent },
  { path: 'users', component: UsersComponent },
  { path: 'questionbuilder', component: QuestionBuilderComponent },
  { path: 'patients', component: PatientsComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
