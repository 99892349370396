
<div class="container">	
	<div style="display: flex; flex-direction: column; justify-content: flex-start; align-content: flex-start;">
		<button mat-raised-button color="warn" class="button-group" *ngIf="hasChanges" (click)="submitQuestions()"><mat-icon>outbound</mat-icon> Click to submit pending changes</button>
		<button style="margin: 1rem;" mat-raised-button color="primary" (click)="showQuestion()">Add New Question</button>
	<div class="background-box">
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-description>
					<mat-icon>filter_alt</mat-icon> 
					<p>  Filter Questions</p>
				  </mat-panel-description>
			</mat-expansion-panel-header>
			<div class="filter-box">
				<mat-form-field appearance="outline">
					<mat-label>Search</mat-label>
					<input matInput type="text" (input)="updateQuestionFilterInputs('search', $event.target.value)">
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Tags</mat-label>
					<mat-select multiple [(ngModel)]="questionFilterInputs.tag" (selectionChange)="updateQuestionFilterInputs('tag', $event.value)">
						<mat-option value="all">All</mat-option>
						<mat-option *ngFor="let tag of currentTags;" value={{tag}}>{{tag}}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</mat-expansion-panel>
		<div cdkDropList (cdkDropListDropped)="drop($event)">
			<mat-selection-list>
				<div class="question-box" cdkDrag *ngFor="let element of shownQuestionList; let i=index" (click)="showQuestion(i)">
					<mat-list-option>
					<mat-icon matListIcon> {{element.icon}} </mat-icon>
					<h2 matLine> {{element.data.title}} </h2>
					</mat-list-option>
					</div>
				</mat-selection-list>
			</div> 
		</div>
	</div>

	<div class="background-box grow">
		<div *ngIf="currentQuestion" class="current-question">
			<div class="question-line">
				<mat-form-field appearance="outline">
					<mat-select [(ngModel)]="currentQuestion.data.type" required (selectionChange)="typeChanged($event)">
						<mat-option value="SINGLE_CHOICE">Radio</mat-option>
						<mat-option value="MULTIPLE_CHOICE">Check Box</mat-option>
						<mat-option value="TEXT">Free Text</mat-option>
						<mat-option value="YES_NO">Yes/No</mat-option>
						<mat-option value="NESTED">Nested</mat-option>
					</mat-select>
					<mat-label>Type</mat-label>
				</mat-form-field>
				<mat-form-field appearance="outline" class="grow">
					<mat-label>Question Name</mat-label>
					<input matInput [(ngModel)]="currentQuestion.data.title" [disabled]="!isNewQuestion && !currentQuestion.notSubmitted" required>
				</mat-form-field>
				<mat-checkbox [(ngModel)]="currentQuestion.required">Mandatory</mat-checkbox>
			</div>
			<mat-form-field appearance="outline">
				<mat-label>Question</mat-label>
				<input matInput [(ngModel)]="currentQuestion.description" required>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Question Hint</mat-label>
				<textarea matInput [(ngModel)]="currentQuestion.hint" rows="5"></textarea>
				<mat-hint>Will be displayed as HTML, so tags can be used for formatting!</mat-hint>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Tags</mat-label>
				<input matInput [(ngModel)]="currentQuestion.rawTags">
				<mat-hint>Comma separated, white space will be ignored</mat-hint>
			</mat-form-field>

			<ng-container *ngIf="this.currentQuestion.data.type === 'NESTED'">
				<ng-container *ngFor="let question of currentQuestion.data.questions; let questionIndex=index">
					<h3>Question {{questionIndex+1}}</h3>
					<div class="questionLine">
						<mat-form-field appearance="outline">
							<mat-select [(ngModel)]="question.type" required (selectionChange)="nestedTypeChanged($event, questionIndex)">
								<mat-option value="SINGLE_CHOICE">Radio</mat-option>
								<mat-option value="MULTIPLE_CHOICE">Check Box</mat-option>
								<mat-option value="TEXT">Free Text</mat-option>
								<mat-option value="YES_NO">Yes/No</mat-option>
							</mat-select>
							<mat-label>Type</mat-label>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Question Text</mat-label>
							<input matInput [(ngModel)]="question.title">
							<input matInput>
						</mat-form-field>
						<button mat-icon-button (click)="deleteNestedQuestion(questionIndex)" color="primary"><mat-icon>remove_circle</mat-icon></button>
					</div>
					<ng-container *ngIf="question.type === 'TEXT'">
						<mat-form-field appearance="outline">
							<mat-label>Regex Validator</mat-label>
							<input matInput [(ngModel)]="question.regex">
							<mat-hint><a href="https://www.w3schools.com/jsref/jsref_obj_regexp.asp">What is a regex?</a></mat-hint>
						</mat-form-field>
					</ng-container>
					<ng-container *ngIf="question.type !== 'TEXT'">
						<div *ngFor="let answer of question.items; let answerIndex=index; trackBy:trackByFn" class="answers" class="questionLine">
							<mat-icon color=primary *ngIf="question.type !== 'YES_NO'">
								{{question.type === "MULTIPLE_CHOICE" ? 'check_box' : null}}
								{{question.type === "SINGLE_CHOICE" ? 'radio_button_checked' : null}}
							</mat-icon>
							<mat-form-field appearance="outline">
								<mat-label>Answer {{answerIndex+1}}</mat-label>
								<input matInput [(ngModel)]="question.items[answerIndex]" [disabled]="this.question.type === 'YES_NO'">
							</mat-form-field>
							<button mat-icon-button (click)="deleteAnswer(answerIndex, questionIndex)" color="primary" *ngIf="question.type !== 'YES_NO'"><mat-icon>remove_circle</mat-icon></button>
							<button mat-icon-button (click)="toggleFlagAnswer(answerIndex, questionIndex)" [color]="isAnswerFlagged(answerIndex, questionIndex) ? 'primary' : 'accent'"><mat-icon>flag</mat-icon></button>
						</div>
						<button mat-raised-button color="primary" (click)="addAnswer(questionIndex)" *ngIf="question.type !== 'YES_NO'" style="max-width: 30%; margin: 1rem">Add Another Answer</button>
					</ng-container>
				</ng-container>
				<button mat-raised-button color="primary" (click)="addNestedQuestion()" style="max-width: 30%; margin: 1rem">Add Another Question</button>
			</ng-container>

			<!-- Only used if not a text question -->
			<div *ngIf="this.currentQuestion.data.type === 'SINGLE_CHOICE' || this.currentQuestion.data.type === 'MULTIPLE_CHOICE' || this.currentQuestion.data.type === 'YES_NO'">
				<div *ngFor="let answer of currentQuestion.data.items; let i=index; trackBy:trackByFn" class="answers">
					<mat-icon color=primary *ngIf="this.currentQuestion.data.type !== 'YES_NO'">
						{{currentQuestion.data.type === "MULTIPLE_CHOICE" ? 'check_box' : null}}
						{{currentQuestion.data.type === "SINGLE_CHOICE" ? 'radio_button_checked' : null}}
					</mat-icon>
					<mat-form-field appearance="outline">
						<mat-label>Answer {{i+1}}</mat-label>
						<input matInput [(ngModel)]="currentQuestion.data.items[i]" [disabled]="this.currentQuestion.data.type === 'YES_NO'">
					</mat-form-field>
					<button mat-icon-button (click)="deleteAnswer(i)" color="primary" *ngIf="this.currentQuestion.data.type !== 'YES_NO'"><mat-icon>remove_circle</mat-icon></button>
					<button mat-icon-button (click)="toggleFlagAnswer(i)" [color]="isAnswerFlagged(i) ? 'primary' : 'accent'"><mat-icon>flag</mat-icon></button>
				</div>
				<button mat-raised-button color="primary" (click)="addAnswer()" *ngIf="this.currentQuestion.data.type !== 'YES_NO'">Add Another Answer</button>
			</div>

			<!-- Only used if it is a text question -->
			<div *ngIf="this.currentQuestion.data.type === 'TEXT'" class="question-line">
				<mat-form-field appearance="outline">
					<mat-label>Regex Validator</mat-label>
					<input matInput [(ngModel)]="currentQuestion.data.regex">
					<mat-hint><a href="https://www.w3schools.com/jsref/jsref_obj_regexp.asp">What is a regex?</a></mat-hint>
				</mat-form-field>
			</div>

		</div>
		<div *ngIf="currentQuestion" class="button-group">
			<button mat-raised-button color="primary" class="button" (click)="saveQuestion()"><mat-icon>save</mat-icon> Save</button>
			<button mat-stroked-button *ngIf="isNewQuestion" color="warn" class="button" (click)="discardQuestion()"><mat-icon>delete</mat-icon> Discard</button>
			<button mat-stroked-button *ngIf="!isNewQuestion" class="button" (click)="revertQuestion()"><mat-icon>undo</mat-icon> Undo Changes</button>
			<button mat-stroked-button *ngIf="!isNewQuestion" color="warn" class="button" (click)="archiveQuestion()"><mat-icon>archive</mat-icon> Archive</button>
		</div>
	</div>
</div>
