<div style="margin: 1rem">
	<table mat-table [dataSource]="patients" style="width: 100%">
		<ng-container matColumnDef="patientID">
			<th mat-header-cell *matHeaderCellDef> Patient ID </th>
			<td mat-cell *matCellDef="let record"> {{record.patientID | slice:-6}} </td>
		</ng-container>
		<ng-container matColumnDef="firstName">
			<th mat-header-cell *matHeaderCellDef> First Name </th>
			<td mat-cell *matCellDef="let record"> {{record.firstName}} </td>
		</ng-container>
		<ng-container matColumnDef="lastName">
			<th mat-header-cell *matHeaderCellDef> Last Name </th>
			<td mat-cell *matCellDef="let record"> {{record.lastName}} </td>
		</ng-container>
		<ng-container matColumnDef="dateOfBirth">
			<th mat-header-cell *matHeaderCellDef> Date Of Birth </th>
			<td mat-cell *matCellDef="let record"> {{record.dateOfBirth | date}} </td>
		</ng-container>
		<ng-container matColumnDef="action">
			<th mat-header-cell *matHeaderCellDef> Actions </th>
			<td mat-cell *matCellDef="let record">
				<button mat-icon-button (click)="editPatient(record)" color="primary"><mat-icon>mode_edit</mat-icon></button>
				<!-- <button mat-icon-button (click)="deletePatient(employee_number)" color="primary"><mat-icon>delete</mat-icon></button> -->
			</td>
		</ng-container>
	
		<tr mat-header-row *matHeaderRowDef="patientColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: patientColumns;"></tr>
	</table>
	<br>
	
	<button mat-raised-button color="primary" (click)="newPatient()">Create New Patient</button>
</div>