import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularSplitModule } from 'angular-split';
import { NgxAudioPlayerModule } from 'ngx-audio-player';

import { MatRadioModule } from  '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { AssessmentComponent } from './assessment/assessment.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AdminComponent } from './admin/admin.component';
import { Report } from './admin/report'
import { QuestionBuilderComponent } from './question-builder/question-builder.component';
import { ErrorComponent } from './error/error.component';
import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { UsersComponent, UserPopup, DeletePopup } from './users/users.component';
import { AssessmentPopup } from './assessment/assessmentPopup';
import { PatientsComponent, PatientPopup } from './patients/patients.component';

@NgModule({
	declarations: [
		AppComponent,
		AssessmentComponent,
		LoginComponent,
		PageNotFoundComponent,
		AdminComponent,
		QuestionBuilderComponent,
		ErrorComponent,
		AudioPlayerComponent,
		Report,
		UserPopup,
		DeletePopup,
		UsersComponent,
		AssessmentPopup,
		PatientsComponent,
		PatientPopup
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		MatRadioModule,
		MatIconModule,
		MatCheckboxModule,
		MatCardModule,
		MatButtonModule,
		FormsModule, 
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		AngularSplitModule,
		DragDropModule,
		MatMenuModule,
		MatListModule,
		MatSelectModule,
		MatTableModule,
		NgxAudioPlayerModule,
		MatDialogModule,
		MatExpansionModule,
		MatSlideToggleModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSnackBarModule
	],
	providers: [ 
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
		{provide: MAT_DATE_LOCALE, useValue: 'en-AU'},],
	bootstrap: [AppComponent]
})
export class AppModule { }
