<div style="margin: 1rem">
	<table mat-table [dataSource]="users" style="width: 100%">

		<!-- Position Column -->
		<ng-container matColumnDef="employee_number">
			<th mat-header-cell *matHeaderCellDef> Employee Number </th>
			<td mat-cell *matCellDef="let record"> {{record.employee_number}} </td>
		</ng-container>
		<ng-container matColumnDef="email">
			<th mat-header-cell *matHeaderCellDef> Email </th>
			<td mat-cell *matCellDef="let record"> {{record.email}} </td>
		</ng-container>
		<ng-container matColumnDef="role">
			<th mat-header-cell *matHeaderCellDef> Role </th>
			<td mat-cell *matCellDef="let record"> {{record.role}} </td>
		</ng-container>
		<ng-container matColumnDef="last_updated">
			<th mat-header-cell *matHeaderCellDef> Most Recent Update </th>
			<td mat-cell *matCellDef="let record"> {{record.last_updated | date}} </td>
		</ng-container>
		<ng-container matColumnDef="action">
			<th mat-header-cell *matHeaderCellDef> Actions </th>
			<td mat-cell *matCellDef="let record">
				<button mat-icon-button (click)="editUser(record)" color="primary"><mat-icon>mode_edit</mat-icon></button>
				<button mat-icon-button (click)="deleteUser(record)" color="primary"><mat-icon>delete</mat-icon></button>
			</td>
		</ng-container>
	
		<tr mat-header-row *matHeaderRowDef="userColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: userColumns;"></tr>
	</table>
	<br>
	<button mat-raised-button color="primary" (click)="newUser()">Create New User</button>
</div>