import { Component, OnInit, ViewChild } from '@angular/core';
import { Track } from 'ngx-audio-player';

@Component({
	selector: 'app-audio-player',
	template: `
	<div style="display: flex; flex-flow: row nowrap; align-items: center;  position: fixed; bottom: 1rem; left: 1rem">
		Load Audio: <input #audioFile type="file" accept=".mp3" (change)="loadFile($event.target.files)">	
		<audio #player controls></audio>

		<!-- <ngx-audio-player [playlist]="audioPlaylist" [displayTitle]="true" [autoPlay]="true" [displayPlaylist]="false" 
		[displayArtist]="false" [displayDuration]="true" [expanded]="true" (trackEnded)="onEnded($event)"
		[displayVolumeControls]="true" [displayRepeatControls]="true" [disablePositionSlider]="false">
		</ngx-audio-player>  -->
	</div>
	
	`,
	styles: [``],
})
export class AudioPlayerComponent implements OnInit {

	@ViewChild('audioFile') audioFile: HTMLInputElement;
	@ViewChild('player') player;

	constructor() { }

	audioPlaylist: Track[] = [
		// {
		//     title: 'Audio One Title',
		//     link: 'https://funksyou.com/fileDownload/Songs/128/13080.mp3',
		// },
];

	ngOnInit(): void {

	}

	loadFile(files: FileList) {
		// let loadedTrack: Track = { title: 'Loaded File', link: URL.createObjectURL((files.item(0))) };
		// this.audioPlaylist.push(loadedTrack);
		this.player.nativeElement.src = URL.createObjectURL((files.item(0)));
	};
}
