<div class="header">
	<img src="../assets/images/HHC-LOGO.png" class="logo">
	<h1 class="title">{{title}}</h1>
	<sup>Alpha v0.9</sup>
	<button mat-icon-button [matMenuTriggerFor]="menu" class="menu-icon" *ngIf="!hasRoute('login')">
		<mat-icon>menu</mat-icon>
	</button>
	<mat-menu #menu="matMenu">
		<button mat-menu-item routerLink="/assessment">
			<mat-icon>assignment_ind</mat-icon>
			<span>New Assessment</span>
		</button>
		<button mat-menu-item routerLink="/questionbuilder" *ngIf="role === 'ADMIN'">
			<mat-icon>quickreply</mat-icon>
			<span>Question Builder</span>
		</button>
		<button mat-menu-item routerLink="/assessmentlist" *ngIf="role === 'ADMIN'">
			<mat-icon>admin_panel_settings</mat-icon>
			<span>Assessment List</span>
		</button>
		<button mat-menu-item routerLink="/users" *ngIf="role === 'ADMIN'">
			<mat-icon>person_pin</mat-icon>
			<span>User Management</span>
		</button>
		<button mat-menu-item routerLink="/patients" *ngIf="role === 'ADMIN'">
			<mat-icon>face</mat-icon>
			<span>Patients</span>
		</button>
		<button mat-menu-item (click)="logout()">
				<mat-icon>logout</mat-icon>
				<span>Logout</span>
			</button>
	</mat-menu>
</div>
<div *ngIf="errorToDisplay">
		<app-error></app-error>
</div>
<router-outlet></router-outlet>