import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	loginGroup = new FormGroup({
		username: new FormControl('', Validators.required),
		password: new FormControl('', Validators.required),
		save: new FormControl(true)
	});

	hasErrors: boolean = false;
	errorMessage: string;

	constructor(private api: ApiService, public router: Router) { }

	ngOnInit(): void {
		if(localStorage.getItem('authToken')) {
			this.api.token = localStorage.getItem('authToken');
			this.router.navigate(['/assessment']);
		}
	}

	submit() {
		this.hasErrors = false
		this.api.getTokenObservable(this.loginGroup.value['username'], this.loginGroup.value['password'], this.loginGroup.value['save'])
		.subscribe(
			(response) => {
				if(response) {
					this.router.navigate(['/assessment']);
				} else {
					this.showError();
				}
			}
		);
	}

	showError() {
		this.hasErrors = true;

		switch(this.api.errorStatus) {
			case 401: { 
				this.errorMessage = 'Invalid username or password';
			   	break; 
			} 
			case 501: { 
				this.errorMessage = 'Backend server error, please wait and try again';
			   	break; 
			} 
			default: { 
				this.errorMessage = 'Unusual error, please check the console logs for more information';
				break; 
			} 
		}
	}
}