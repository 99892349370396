<mat-icon (click)="print()">print</mat-icon>

<div id="dataToPrint">
    <mat-dialog-content>
        <h2>Patient Info</h2>
        <b>Patient Number:  </b>{{patientInfo.patient_number}}<br>
        <b>Name:  </b>{{patientInfo.firstname}} {{patientInfo.lastname}}<br>
        <b>Date of Birth:  </b>{{patientInfo.dateofbirth | date}}<br>

        <h3>Emergency Contacts</h3>
        <ng-container *ngFor="let contact of patientInfo.emergency_contacts">
            <b>Name:  </b>{{contact.firstname}} {{contact.lastname}}<br>
            <b>Relationship:  </b>{{contact.relationship}}<br>
            <b>Contact Number:  </b>{{contact.contact_number}}<br>
        </ng-container>

        <h3>HealthCare Contacts</h3>
        <ng-container *ngFor="let contact of patientInfo.healthcare_contacts">
            <b>Name:  </b>{{contact.title}} {{contact.lastname}}<br>
            <b>Address:  </b>{{contact.address}}<br>
            <b>Contact Number:  </b>{{contact.contact_number}}<br>
        </ng-container>
        <hr>
        <h2>Flagged Questions</h2>
        <ng-container *ngFor="let element of questions;">
            <ng-container *ngIf="element.flagged" style="display: flex; flex-direction: row">
                <div>
                    <p><b>Question: {{element.description}}</b>
                        {{element.data.value? answerString(element) : 'Not Answered'}}
                    </p>
                </div>
                <div style="border: solid black 1px; height: 6rem"></div>
            </ng-container>
        </ng-container>
        <br>
        <hr>
        <h2>Questions</h2>
        <ng-container *ngFor="let element of questions">
            <ng-container *ngIf="!element.flagged">
                <p>
                <b>Question: {{element.description}}</b>
                <ng-container *ngIf="element.data.type === 'TEXT'">
                    {{element.data.value? element.data.value : 'Not Answered'}}
                </ng-container>
                <ng-container *ngIf="element.data.type !== 'TEXT'">
                    {{element.data.value? answerString(element) : 'Not Answered'}}
                </ng-container>
                </p>
            </ng-container>
        </ng-container>
    </mat-dialog-content>
</div>