import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { BehaviorSubject } from 'rxjs';
import { Report } from './report'

export interface SubmittedAssessment {
	assessmentID: string;
	patientID: string;
	assessorID: string;
	date: string;
}

@Component({
	selector: 'app-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

	constructor(public dialog: MatDialog, private api: ApiService) { }

	public submittedAssessments: BehaviorSubject<SubmittedAssessment[]> = new BehaviorSubject<SubmittedAssessment[]>([]);
	public responseObject: Object;

	assessmentColumns: string[] = ['assessmentID', 'patientID', 'assessorID', 'date', 'action'];

	ngOnInit() {
		this.api.getAssessments().subscribe(
			(response) => {
				this.responseObject = response;
				let submittedAssessmentArray = [];
				for(const assessment of response as Array<object>[]) {
					submittedAssessmentArray.push({
						assessmentID: assessment['uuid'],
						patientID: assessment['patient_uuid'],
						assessorID: assessment['assessor_uuid'],
						date: assessment['created']
					} as SubmittedAssessment);
				}
				this.submittedAssessments.next(submittedAssessmentArray);
			},
			(error) => {
				console.log(error);
			}
		)
	 }

	public previewAssessment(record: object) { 
		const selectedID = record['assessmentID'];
		const allResponses = this.responseObject as Array<object>[];
		const selectedAssessment = allResponses.find(assessment => assessment['uuid'] === selectedID);
		this.dialog.open(Report, {data: selectedAssessment});
	}

	public editAssessment() {

	}

	// public archiveAssessment(targetPatientId: string) {
	// 	let tempTableData: SubmittedAssessment[] = [];
	// 	for(const element of this.submittedAssessments) {
	// 		if(element.patientID !== targetPatientId) {
	// 			tempTableData.push(element);
	// 		}
	// 	}
	// 	this.submittedAssessments = tempTableData;
	// }
}

