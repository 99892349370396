import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Question, QuestionTypeMultipleChoice, QuestionTypeSingleChoice, QuestionTypeText, QuestionTypeYesNo, QuestionTypeNested } from '../assessment/question';
import { ApiService } from '../api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface QuestionFilterInputs {
	search: string;
	tag: string[];
}

@Component({
	selector: 'app-question-builder',
	templateUrl: './question-builder.component.html',
	styleUrls: ['./question-builder.component.scss']
})
export class QuestionBuilderComponent implements OnInit {

	questionList: Question[] = [];
	shownQuestionList: Question[] = [];
	cleanQuestionList: Question[] = [];
	currentQuestion: Question;
	hasChanges = false;
	isNewQuestion = true;
	typeArray: string[] = [];
	currentTags: string[] = [];
	questionFilterInputs: QuestionFilterInputs = {
		search: undefined,
		tag: ['all'],
	}
	submitMessage: string = '';
	oldKeys: string[] = ['all'];
	mainTitle: string = '';

	constructor(private api: ApiService, private snackBar: MatSnackBar) { }

	ngOnInit(): void {
		
		// Get the latest list of questions from the server and format it into a useable list.
		this.api.getQuestionsObservable().subscribe(
			(response) => {
				this.questionList = response['questions'];
				this.cleanQuestionList = JSON.parse(JSON.stringify(response['questions']));

				console.log(this.questionList);
				// Iterate over all of the questions and do some additional set up tasks.
				for(const question of this.questionList) {

					// Load the flag array for the text questions and set the comma separated sting for the question that will be displayed.
					if(question.data.type === 'TEXT' && question.data['flag_values'] !== null) {
						let questionFlagString = '';
						for (const flags of question.data['flag_values']) {
							questionFlagString += flags + ', ';
						}
						question.rawFlags = questionFlagString.slice(0, -2);
					}
					
					// Load the tag array and set the comma separated sting for the question that will be displayed.
					let questionTagString = '';
					for (const tags of question.tags) {
						questionTagString += tags + ', ';
					}
					question.rawTags = questionTagString.slice(0, -2);

					// Set the question enum based on the text that is sent back
					question.data.type 
				}

				this.shownQuestionList = this.questionList;
				this.updateTags();
			},
			(error) => {
				this.submitMessage = error;
			}
		)
	}

	public toggleFlagAnswer(answerIndex: number, questionIndex: number) {
		let flag_values;

		if(this.currentQuestion.data.type === "NESTED") {
			flag_values = this.currentQuestion.data['questions'][questionIndex].flag_values as Array<string | number>;
		} else {
			flag_values = this.currentQuestion.data['flag_values'] as Array<string | number>;
		}

		if(flag_values !== undefined && flag_values.includes(answerIndex)) {
			flag_values.splice(flag_values.findIndex(value => value === answerIndex), 1);
		} else {
			flag_values.push(answerIndex);
		}
	}

	public isAnswerFlagged(answerIndex: number, questionIndex?: number): boolean {
		let flag_values;
		if(this.currentQuestion.data.type === "NESTED") {
			flag_values = this.currentQuestion.data['questions'][questionIndex].flag_values as Array<string | number>;
		} else {
			flag_values = this.currentQuestion.data['flag_values'] as Array<string | number>;
		}
		return flag_values.includes(answerIndex) ? true : false;
	}

	public addAnswer(index?: number) {
		if(this.currentQuestion.data.type === "NESTED") {
			let question = this.currentQuestion.data['questions'];
			question[index].items.push('');
		} else if('items' in this.currentQuestion.data) {
			this.currentQuestion.data.items.push('');
		}
	}

	public deleteAnswer(answerIndex: number, questionIndex?: number) {
		if(this.currentQuestion.data.type === "NESTED") {
			let question = this.currentQuestion.data['questions'];
			question[questionIndex].items.splice(answerIndex, 1);
		} else if('items' in this.currentQuestion.data) {
			this.currentQuestion.data.items.splice(answerIndex, 1);
		}
	}

	/** Takes the selected question from the list and sets it for display, defaulting to a new question when no index is provided.
	 * 
	 * @param index the number in the questionList that the user has selected. 
	 */
	public showQuestion(index?: number) {
		if(index !== undefined) {
			this.isNewQuestion = false;
			this.currentQuestion = this.questionList[this.questionList.indexOf(this.shownQuestionList[index])];
		} else {
			this.currentQuestion = new Question();
			this.currentQuestion.icon = 'question_answer';
			const data: QuestionTypeMultipleChoice = {
				title: '',
				type: 'MULTIPLE_CHOICE',
				items: [''],
				value: undefined,
				flag_values: []
			}
			this.currentQuestion.data = data;
			this.isNewQuestion = true;
		}
	}

	/** Saves the current question to the question list and then deselects the question.
	 * 
	 */
	public saveQuestion() {
		if (this.isNewQuestion) {
			this.currentQuestion.notSubmitted = true;
			this.questionList.push(this.currentQuestion);
		}
		this.currentQuestion = undefined;
		this.isNewQuestion = false;
		this.hasChanges = true
		this.filterQuestions();
	}

	public discardQuestion() {
		this.currentQuestion = undefined;
	}

	public submitQuestions() {
		const submittedTemplate = Question.rebuildAssessmentTemplate(this.questionList);
		this.api.submitQuestions(submittedTemplate).subscribe(
			() => {
				this.hasChanges = false;
				for(const question of this.questionList) {
					question.notSubmitted = false;
				}
				const currentDate = new Date();
				this.submitMessage = 'Submit successful on ' + currentDate.toLocaleString();
				this.snackBar.open(this.submitMessage, 'close', {duration: 10000});
			},
			(error) => {
				this.snackBar.open(error, 'close', {duration: 10000});
			}
		);
	}

	public updateTags() {
		this.currentTags = [];
		for(const question of this.questionList) {
			for(const tag of question.tags) {
				if(!this.currentTags.includes(tag)) {
					this.currentTags.push(tag);
				}
			}
		}
	}

	public archiveQuestion() {
		this.questionList.splice(this.questionList.findIndex((question) => {
			return question.data.title === this.currentQuestion.data.title;
		}), 1);
		this.hasChanges = true;
		this.currentQuestion = undefined;
		this.filterQuestions();
	}

	public revertQuestion() {
		const cleanIndex = this.cleanQuestionList.findIndex((question) => {
			return question.data.title === this.currentQuestion.data.title;
		});
		const currentIndex = this.questionList.findIndex((question) => {
			return question.data.title === this.currentQuestion.data.title;
		});
		this.questionList[currentIndex] = this.cleanQuestionList[cleanIndex];
		this.discardQuestion();
		this.showQuestion(currentIndex);
	}

	/** Is triggered each time the type of question in the dropdown in changed.
	 * 
	 * @param event
	 */
	public typeChanged(event) {
		this.hasChanges = true;
		this.mainTitle = this.currentQuestion.data.title;
		this.currentQuestion.data = this.setQuestionDefaults(event['value']);
		this.currentQuestion.data.title = this.mainTitle;
	}

	public nestedTypeChanged(event, questionIndex) {
		this.hasChanges = true;
		this.currentQuestion.data['questions'][questionIndex] = this.setQuestionDefaults(event['value']);
	}

	private setQuestionDefaults(questionType): any {
		let data;
		if(questionType === 'YES_NO') {
			data = {
				title: '',
				type: questionType,
				items: ['Yes', 'No'],
				value: null,
				flag_values: []
			} as QuestionTypeYesNo;
		} else if(questionType === 'TEXT') {
			data = {
				title: '',
				type: questionType,
				regex: '',
				value: '',
				flag_values: []
			} as QuestionTypeText;
		} else if(questionType === 'SINGLE_CHOICE') {
			data = {
				title: '',
				type: questionType,
				items: [],
				value: null,
				flag_values: []
			} as QuestionTypeSingleChoice;
		} else if(questionType === 'MULTIPLE_CHOICE') {
			data = {
				title: '',
				type: questionType,
				items: [],
				value: [],
				flag_values: []
			} as QuestionTypeMultipleChoice;
		} else if(questionType === 'NESTED') {
			data = {
				type: questionType,
				questions: []
			} as QuestionTypeNested;
		}
		return data;
	}

	public updateQuestionFilterInputs(inputKey: string, value) {

		this.questionFilterInputs[inputKey] = value;

		if(inputKey !== 'search') {
			const allNewIndex = this.questionFilterInputs[inputKey].findIndex(key => key === 'all');
			const allOldIndex = this.oldKeys.findIndex(key => key === 'all');

			if(allNewIndex !== -1 && allOldIndex === -1) {
				this.questionFilterInputs[inputKey] = ['all']
			} else if (this.questionFilterInputs[inputKey].length > 0) {
				this.questionFilterInputs[inputKey] = this.questionFilterInputs[inputKey].filter(key => key !== 'all');
			}
			this.oldKeys = [...this.questionFilterInputs[inputKey]];	
		}

		this.filterQuestions();
	}

	public filterQuestions() {
		this.shownQuestionList = this.questionList;
		// Iterate through the values that we care to search for the provided string, skipping over the rest one a match is found. 
		if (this.questionFilterInputs.search) {
			this.shownQuestionList = this.shownQuestionList.filter(question => {
				let matched = false;
				const search = this.questionFilterInputs.search.toLowerCase();
				if ('items' in question.data) {
					matched = question.data.items.toString().toLowerCase().includes(search);
				}
				if(!matched) {
					matched = question.description.toLowerCase().includes(search);
				}
				if(!matched) {
					matched = question.data.title.toLowerCase().includes(search);
				}
				return matched;
			});
		}
		// iterate through each tag of each question and add it back into the list if that tag is also in the list of selected tags 
		// from the filter, don't do this if the all tag is selected.
		if (this.questionFilterInputs.tag.length >= 0 && !this.questionFilterInputs.tag.includes('all')) {
			let filteredQuestions: Question[] = [];
			for (const question of this.shownQuestionList) {
				for (const searchTag of this.questionFilterInputs.tag) {
					for(const questionTag of question.tags)
					if (questionTag === searchTag) {
						filteredQuestions.push(question);
					}
				}
			}
			this.shownQuestionList = filteredQuestions;
		}
	}

	trackByFn(index: any) {
		return index;
	}

	drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.questionList, event.previousIndex, event.currentIndex);
		this.hasChanges = true;
	}

	addNestedQuestion() {
		if(this.currentQuestion.data.type === "NESTED") {
			let question = this.currentQuestion.data['questions'];
			if(question === undefined){
				question = [{type: 'MULTIPLE_CHOICE',
				title: '',
				items: [''],
				value: undefined,
				flag_values: []}];
			} else {
				question.push({type: 'MULTIPLE_CHOICE',
				title: '',
				items: [''],
				value: undefined,
				flag_values: []});
			}
			this.currentQuestion.data['questions'] = question;
		}
	}

	public deleteNestedQuestion(questionIndex: number) {
		console.log(this.currentQuestion);
		console.log(questionIndex)
		this.currentQuestion.data['questions'].splice(questionIndex, 1);
	}
}