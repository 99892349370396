<div class="container">
	<div style="margin: 1rem">
		<button mat-raised-button color="warn" (click)="selectPatient()" *ngIf="popupShown && currentPatient === ''" class="patientButton">
			<mat-icon>face</mat-icon> Select a Patient</button>
	</div>
	<as-split direction="horizontal">
		<!-- The left window -->
		<as-split-area [size]="65">
			<div class="splitContainer">
				<!-- The notes, buttons, and audio playback controls when not in card mode -->
				<div class="form-group" *ngIf="!cardMode">
					<button mat-raised-button color="primary" (click)="toggleCardMode()" style="margin-left: 1rem">{{cardMode?"Text Mode":"Card Mode"}}</button>
					<mat-form-field appearance="outline" class="fill-container">
						<mat-label>Assessment Notes</mat-label>
						<textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="23" [(ngModel)]="notes"></textarea>
					</mat-form-field>
					<div class="audioBox">
						<app-audio-player></app-audio-player>
					</div>
				</div>

				<!-- The notes from the above textarea are broken up into individual cards when the user selects to do so -->
				<mat-card class="row" *ngIf="cardMode">
					<mat-icon>filter_alt</mat-icon>
					<mat-form-field appearance="outline">
						<mat-label>Filter Notes</mat-label>
						<input matInput type="text" (input)="filterNotes($event.target.value)">
					</mat-form-field>
					<!-- <mat-button-toggle-group #group="matButtonToggleGroup" (click)="toggleCardMode($event)">
						<mat-button-toggle value="card">
							<mat-icon>view_agenda</mat-icon>
						</mat-button-toggle>
						<mat-button-toggle value="text">
							<mat-icon>view_headline</mat-icon>
						</mat-button-toggle>
					  </mat-button-toggle-group> -->
					<button mat-raised-button color="primary" (click)="toggleCardMode()">{{cardMode?"Text Mode":"Card Mode"}}</button>
				</mat-card>

				<!-- cdkDrag allows the user to drag that element, and the cdkDropList keeps them in a listed view otherwise you can drag and drop anywhere -->
				<ng-container *ngIf="cardMode">
					<div 
					*ngFor="let element of shownNoteLines"
					id="noteCards"
					cdkDropList [cdkDropListData]="shownNoteLines" 
					(cdkDropListDropped)="dropped($event)"
					[cdkDropListConnectedTo]="dropContainerIds">
						<div cdkDrag (cdkDragStarted)="dragStarted()" [cdkDragData]="element">
							<mat-card>&#8226; {{element}}</mat-card>
						</div>
					</div>
				</ng-container>
			</div>
	  	</as-split-area>

		<!-- The right window -->
		<as-split-area [size]="35">
			<div class="splitContainer">
				<!-- This shows all of the filtering options for the question list -->
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-description>
							<mat-icon>filter_alt</mat-icon>
							<p>  Filter Questions</p>
						  </mat-panel-description>
					</mat-expansion-panel-header>
					<div class="filter-box">
						<mat-form-field appearance="outline">
							<mat-label>Search</mat-label>
							<input matInput type="text" (input)="updateQuestionFilterInputs('search', $event.target.value)">
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Tags</mat-label>
							<mat-select multiple [(ngModel)]="questionFilterInputs.tag" (selectionChange)="updateQuestionFilterInputs('tag', $event.value)">
								<mat-option value="all">All</mat-option>
								<mat-option *ngFor="let tag of currentTags;" value={{tag}}>{{tag}}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>States</mat-label>
							<mat-select multiple [(ngModel)]="questionFilterInputs.state" (selectionChange)="updateQuestionFilterInputs('state', $event.value)">
								<mat-option value="all">All</mat-option>
								<mat-option value="complete">Show Complete</mat-option>
								<mat-option value="incomplete">Show Incomplete</mat-option>
								<mat-option value="flagged">Show Flagged</mat-option>
								<mat-option value="required">Show Required</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</mat-expansion-panel>
				<!-- This shows all of the questions that are in the question list -->
				<ng-container *ngFor="let element of shownQuestionList; let questionListIndex=index">
					<mat-expansion-panel [ngStyle]="{'background-color': element.questionAnswered ? '#00B126' : ''}"
					[cdkDropData]="element" id="{{element.dragId}}" cdkDropList [cdkDropListData]="element.relevantNotes" (cdkDropListDropped)="dropped($event)">
						<mat-expansion-panel-header>
							<mat-panel-description>
								<p>{{element.description}}{{element.required? ' [Required]' : ''}}</p>
							  </mat-panel-description>
						</mat-expansion-panel-header>
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<button mat-icon-button><mat-icon>info</mat-icon>help</button>
							</mat-expansion-panel-header>
							{{element.hint}}
						</mat-expansion-panel>
						<ng-container *ngIf="element.notes || isDragging" style="background: red; height: 50px">
							<ng-container *ngFor="let note of element.notes">
								<p>{{note}}</p>
							</ng-container> 
						</ng-container>
						
						<mat-radio-group [required]="element.required" class="answer-list">
							<ng-container *ngIf="element.data.type === 'SINGLE_CHOICE' || element.data.type === 'MULTIPLE_CHOICE' || element.data.type === 'YES_NO'">
								<ng-container *ngFor="let answer of element.data.items; let answerIndex=index">
									<mat-radio-button *ngIf="element.data.type === 'SINGLE_CHOICE' || element.data.type === 'YES_NO'" (change)="updateChoiceAnswer($event, questionListIndex)" [value]="answerIndex" [checked]="isCheckedAnswer(answerIndex, element.data.value)">
										{{answer}}<ng-container *ngIf="isInFlaggedList(answerIndex, questionListIndex)"> [Flagged Answer]</ng-container></mat-radio-button>
									<mat-checkbox *ngIf="element.data.type === 'MULTIPLE_CHOICE'" (change)="updateChoiceAnswer($event, questionListIndex)" [value]="answerIndex" [checked]="isCheckedAnswer(answerIndex, element.data.value)">
										{{answer}}<ng-container *ngIf="isInFlaggedList(answerIndex, questionListIndex)"> [Flagged Answer]</ng-container></mat-checkbox>
								</ng-container>
							</ng-container>
						</mat-radio-group>

							<ng-container *ngIf="element.data.type === 'TEXT'">
								<mat-form-field appearance="outline" class="fill-container">
									<mat-label>Answer</mat-label>
									<textarea matInput [(ngModel)]="element.data.value" rows="2" [required]="element.required" (change)="saveToLocal()"></textarea>
								</mat-form-field>
							</ng-container>

							<ng-container *ngIf="element.data.type === 'YES_NO'">
								<mat-radio-group [required]="element.required" class="answer-list">
									<mat-radio-button (change)="updateChoiceAnswer($event, questionListIndex)" [value]="0" [checked]="value === element.data.value">
										Yes<ng-container *ngIf="isInFlaggedList(i, questionListIndex)"> [Flagged Answer]</ng-container></mat-radio-button>
									<mat-radio-button (change)="updateChoiceAnswer($event, questionListIndex)" [value]="1" [checked]="value === element.data.value">
										No<ng-container *ngIf="isInFlaggedList(i, questionListIndex)"> [Flagged Answer]</ng-container></mat-radio-button>
								</mat-radio-group>
							</ng-container>

							<ng-container *ngIf="element.data.type === 'NESTED'">
								<ng-container *ngFor="let question of element.data.questions; let nestedQuestionIndex=index">
									<h4>{{question.title}}</h4>
									<mat-radio-group  class="answer-list">
										<ng-container *ngIf="question.type === 'SINGLE_CHOICE' || question.type === 'MULTIPLE_CHOICE' || question.type === 'YES_NO'">
											<ng-container *ngFor="let answer of question.items; let answerIndex=index">
												<mat-radio-button *ngIf="question.type === 'SINGLE_CHOICE'  || question.type === 'YES_NO'" (change)="updateNestedChoiceAnswer($event, questionListIndex, nestedQuestionIndex)" 
												[value]="answerIndex" [checked]="isCheckedAnswer(nestedQuestionIndex, answer.value)">
													{{answer}}<ng-container *ngIf="isInFlaggedList(answerIndex, nestedQuestionIndex)"> [Flagged Answer]</ng-container></mat-radio-button>
												<mat-checkbox *ngIf="question.type === 'MULTIPLE_CHOICE'" (change)="updateNestedChoiceAnswer($event, questionListIndex, nestedQuestionIndex)" 
												[value]="answerIndex" [checked]="isCheckedAnswer(nestedQuestionIndex, answer.value)">
													{{answer}}<ng-container *ngIf="isInFlaggedList(answerIndex, nestedQuestionIndex)"> [Flagged Answer]</ng-container></mat-checkbox>
											</ng-container>
										</ng-container>

										<ng-container *ngIf="question.type === 'TEXT'">
											<mat-form-field appearance="outline" class="fill-container">
												<mat-label>Answer</mat-label>
												<textarea matInput [(ngModel)]="question.value" rows="2" (change)="saveToLocal()"></textarea>
											</mat-form-field>
										</ng-container>
									</mat-radio-group>

									<br>
								</ng-container>
							</ng-container>

						<br>
						<div (click)="element.userFlagged = true">
							<mat-checkbox [disabled]="element.flagged" [checked]="element.flagged">Flag as Important</mat-checkbox>
							<mat-form-field appearance="outline" *ngIf="element.userFlagged && !element.flagged">
								<mat-label>Reason</mat-label>
								<input matInput type="text">
							</mat-form-field>
						</div>
					</mat-expansion-panel>
				</ng-container>
				<button mat-raised-button color="primary" (click)="submitAssessment()" [disabled]="!allValid"><mat-icon>outbound</mat-icon> Submit</button>
			</div>
		</as-split-area>
	</as-split>
</div>