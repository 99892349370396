<div class="flexDiv">
		<mat-card>
		<div class='loginIcon'>
				<mat-icon color="primary">person_pin</mat-icon>
		</div>
		<br>
		<form [formGroup]="loginGroup">
			<mat-form-field appearance="outline">
				<mat-label>Email or Username</mat-label>
				<input matInput placeholder="someone@example.com" formControlName="username">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline">
				<mat-label>Password</mat-label>
				<input matInput type="Password" formControlName="password">
			</mat-form-field>
			<br>
			<mat-checkbox formControlName="save">Stay Logged in</mat-checkbox>
			<br>
			<br>
			<div *ngIf="hasErrors" class='invalidCredentials'>
				{{errorMessage}}
			</div>
			<button mat-raised-button color="primary" (click)="submit()" [disabled]="!loginGroup.valid">Sign In</button>
		</form>
	</mat-card>
</div>