import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Patient {
	patientUuid: string;
	patientID: string;
	FirstName: string;
	LastName: string;
	dateOfBirth: string;
}

@Component({
	selector: 'app-assessmentPopup',
	templateUrl: './assessmentPopup.html',
})
export class AssessmentPopup implements OnInit {

	public patients: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);
	public responseObject: Object;
	public patientColumns: string[] = ['patientID', 'firstName', 'lastName', 'dateOfBirth', 'action'];
	public savedAssessment = false;

	constructor(private api: ApiService, public dialogRef: MatDialogRef<AssessmentPopup>) {

	}

	ngOnInit() {
		if(localStorage.getItem('questionList')) {
			this.savedAssessment = true;
		}
		this.api.getPatients().subscribe(
			(response) => {
				this.responseObject = response;
				let patients = [];
				for(const patient of response as Array<object>[]) {
					patients.push({
						patientUuid: patient['uuid'],
						patientID: patient['patient_number'],
						firstName: patient['firstname'],
						lastName: patient['lastname'],
						dateOfBirth: patient['dateofbirth']
					});
				}
				this.patients.next(patients);
			},
			(error) => {
				console.log(error);
			}
		)
	}

	public loadAssessment(){
		this.dialogRef.close({data:'LOAD'});
	}

	public newAssessment(record: Patient){
		this.dialogRef.close({data:record});
	}
}

