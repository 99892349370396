import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-report',
	templateUrl: './report.html',
	styleUrls: ['./report.scss'] 
})
export class Report {

	public questions;
	public patientInfo;

	constructor(private api: ApiService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<Report>) {
		this.questions = data['questions'];
		console.log('Report Data', data)

		this.api.getPatient(data['patient_uuid']).subscribe(
			(response) => {
				this.patientInfo = response;
			},
			(error) => {
				console.log(error);
			}
		)
	}

	print() {
		let virtualWindow: any = window.open('', 'PRINT', 'height=400,width=800');
		virtualWindow.document.write('<html>' + document.getElementById('dataToPrint').innerHTML + '</html>'); 
		virtualWindow.document.close();
	}

	answerString(question): string {
		console.log('question: ', question);
		let answerString = '';

		if(Array.isArray(question.data.value)) {
			for(const value of question.data.value) {
				answerString += question.data.items[value] + ', ';
			}
			answerString = answerString.slice(0, -2);
		} else {
			answerString = question.data.items[question.data.value];
		}
		

		if (answerString === '') {
			answerString = 'Not Answered';
		}
		console.log('answer: ', answerString);
		return answerString;
	}
}

