import { Component, OnInit } from '@angular/core';
import { Question } from './question';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { AssessmentPopup } from './assessmentPopup';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface QuestionFilterInputs {
	search: string;
	type: string[];
	tag: string[];
	state: string[]
}

@Component({
	selector: 'app-assessment',
	templateUrl: './assessment.component.html',
	styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {

	questionList: Question[] = [];
	shownQuestionList: Question[] = [];
	notes: string = '';
	noteLines: string[];
	shownNoteLines: string[];
	cardMode = false;
	currentTags: string[] = [];
	questionFilterInputs: QuestionFilterInputs = {
		search: undefined,
		type: ['all'],
		tag: ['all'],
		state: ['all']
	};
	isDragging = false;
	dropContainerIds: string[] = [];
	currentUuid: string = '';
	currentPatient: string = '';
	allValid = false;
	popupShown = false;
	oldTagKeys = ['all'];
	oldStateKeys = ['all'];

	constructor(private api: ApiService, public dialog: MatDialog, private snackBar: MatSnackBar) { }

	ngOnInit(): void {
		this.selectPatient();
	}

	public selectPatient() {
		const dialogRef = this.dialog.open(AssessmentPopup);

		dialogRef.afterClosed().subscribe(result => {
			if(result !== undefined && result.data === 'LOAD') {
				this.loadFromLocal();
			} else if (result !== undefined) {
				this.currentPatient = result.data['patientUuid'];
				this.clearLocal();
				this.setUpAssessment();
			}
			this.popupShown = true;
		});
	}

	public setUpAssessment() {
		// Get the latest list of questions from the server and format it into a useable list.
		this.api.getQuestionsObservable().subscribe(
			(response) => {
				console.log('all questions:', response);
				this.questionList = response['questions'];
				this.currentUuid = response['uuid'];
				this.shownQuestionList = this.questionList;
				this.updateTags();

				// give a unique ID to the drop container HTML elements so they can have cards assigned to them.
				for (let element of this.shownQuestionList) {
					element.dragId = Math.random()*1000;
					this.dropContainerIds.push(element.dragId.toString());
				};
				this.dropContainerIds.push('noteCards');
			},
			(error) => {
				console.log(error);
			}
		)
	}

	/** Goes through every question and looks at each tag, if it has not been added to the list of tags 
	 * (that are then used by things like filters) it adds them.
	 */
	public updateTags() {
		this.currentTags = [];
		for(const question of this.questionList) {
			for(const tag of question.tags) {
				if(!this.currentTags.includes(tag)) {
					this.currentTags.push(tag);
				}
			}
		}
	}

	/** A method that is used to filter questions based on the currently selected filters. 
	 * It works by going through each question and looking for the filtered attribute, 
	 * if not found then it is not added to filterQuestionList, which is what is displayed by the UI.
	 */
	public filterQuestions() {
		this.shownQuestionList = this.questionList;
		if (this.questionFilterInputs.search) {
			this.shownQuestionList = this.shownQuestionList.filter(question => {
				// If it is a question type that has text in answers search it.
				if ('items' in question.data) {
					question.data.items.includes(this.questionFilterInputs.search);
				}
				question.description.includes(this.questionFilterInputs.search)
			});
		}
		if (this.questionFilterInputs.type && !this.questionFilterInputs.type.includes('all')) {
			let filteredQuestions: Question[] = [];
			for (const question of this.shownQuestionList) {
				for (const typeFilter of this.questionFilterInputs.type) {
					if (question.data.type.toString() === typeFilter) {
						filteredQuestions.push(question);
					}
				}
			}
			this.shownQuestionList = filteredQuestions;
		}
		if (this.questionFilterInputs.tag && !this.questionFilterInputs.tag.includes('all')) {
			// TODO: have this check all tags once they are added
		}
		if (this.questionFilterInputs.state && !this.questionFilterInputs.state.includes('all')) {
			let filteredQuestions: Question[] = [];
			for (const question of this.shownQuestionList) {
				for (const completeStatus of this.questionFilterInputs.state) {
					if (completeStatus === 'complete' && question.questionAnswered === true) {
						filteredQuestions.push(question);
					} else if (completeStatus === 'incomplete' && question.questionAnswered === false) {
						filteredQuestions.push(question);
					} else if (completeStatus === 'flagged' && question.flagged === true) {
						filteredQuestions.push(question);
					} else if (completeStatus === 'required' && question.required === true) {
						filteredQuestions.push(question);
					}
				}
			}
			this.shownQuestionList = filteredQuestions;
		}
	}

	/** A method that is used to filter notes based on the currently selected search string. 
	 * Each just looks through each note card's text for the specific string, if not found it
	 * is not added to the shownNoteLines, which is what the UI displays.
	 */
	public filterNotes(searchString?: string) {
		this.shownNoteLines = this.noteLines;
		if (searchString) {
			this.shownNoteLines = this.noteLines.filter(text => text.includes(searchString));
		}
	}

	/** A method that iterates over each question in the list and marks the allValid boolean wither true or false depending on 
	 * whether all validation criteria are met. 
	 */
	public validateQuestions() {
		for(const question of this.questionList) {
			if(question.data.type === 'NESTED'){
				let hasAnAnswer = false;
				for(const nestedQuestion of question.data['questions']) {
					if(!hasAnAnswer) {
						hasAnAnswer = nestedQuestion['value'] !== null && nestedQuestion['value'] !== undefined && nestedQuestion['value'] !== "";
					}
				}
				if(!hasAnAnswer) {
					this.allValid = false;
					return;
				}
			} else if(question.required && question.data['value'] !== 0 && !question.data['value']) {
				this.allValid = false;
				return;
			} else if (question.data['regex'] && !question.data['value'].toString().match(question.data['regex'])) {
				this.allValid = false;
				return;
			}
		}
		this.allValid = true;
	}

	// --------------------------------------------------------------------------------------------------------- UI methods

	/** A method that is triggered when a card is dropped, mostly it is responsible for adding the data 
	 * to the questions if they are dropped in the correct spot.
	 */
	public dropped(event: CdkDragDrop<string[]>) {
		if (event.previousContainer !== event.container) {
			this.questionList[this.questionList.findIndex(question => question.dragId.toString() === event.container.id)].notes.push(event.item.data);
		}
		this.isDragging = false;
	}

	/** A method used when dragging of a card is started, at the moment it only sets a bool that is used by 
	 * various UI to show/hide elements.
	 */
	public dragStarted() {
		this.isDragging = true;
	}

	/** A method that switches between input and card view mode, it does a little more processing to split 
	 * the wall of text into lines which are then shown as cards.
	 */
	public toggleCardMode() {
		this.cardMode = !this.cardMode;
		this.noteLines = this.notes.split(/\n/g);
		this.shownNoteLines = this.noteLines;
	}

	/** A method that updates the value of a question depending on what was selected in the UI.
	 * 
	 * @param AnswerEvent is the event from the form group
	 * @param questionIndex is the index of the current question being answered
	 */
	public updateChoiceAnswer(AnswerEvent: Event, questionIndex: number) {
		// Find the matching element of the questionList to the shownQuestionList as that is what we want to check and update.
		const convertedQuestionIndex = this.questionList.findIndex(question => question.data.title === this.shownQuestionList[questionIndex].data.title);
		let flag_values = this.questionList[convertedQuestionIndex].data['flag_values'] as Array<number>;
		this.questionList[convertedQuestionIndex].flagged = flag_values.includes(AnswerEvent["value"]);

		// Need to do extra checking on the list of checkboxes because the event is only if that index is 
		// checked or not without the context of the whether the rest are or aren't.
		if(this.questionList[convertedQuestionIndex].data.type === 'MULTIPLE_CHOICE' && AnswerEvent['checked'] !== null) {
			console.log('multiple choice');
			const questionAnswer = this.questionList[convertedQuestionIndex].data['value'] as Array<number>;
			if (AnswerEvent['checked']) {
				questionAnswer.push(AnswerEvent['source']['value']);
				console.log('added question answer', questionAnswer);
			} else {
				questionAnswer.splice(questionAnswer.findIndex(value => value === AnswerEvent['source']['value']), 1);
				console.log('removed question answer', questionAnswer);
			}
		} else {
			this.questionList[convertedQuestionIndex].data['value'] = AnswerEvent['source']['value'];
		}
		
		this.saveToLocal();
		this.validateQuestions();
	}

	updateNestedChoiceAnswer(AnswerEvent: Event, questionIndex: number, nestedQuestionIndex: number) {
		console.log('answer event', AnswerEvent, 'qi', questionIndex, 'nqi', nestedQuestionIndex);
		const convertedQuestionIndex = this.questionList.findIndex(question => question.data.title === this.shownQuestionList[questionIndex].data.title);

		if(this.questionList[convertedQuestionIndex].data['questions'][nestedQuestionIndex]['type'] === 'MULTIPLE_CHOICE' && AnswerEvent['checked'] !== null) {
			console.log('multiple choice');
			let questionAnswer = this.questionList[convertedQuestionIndex].data['questions'][nestedQuestionIndex]['value'] as Array<number>;
			console.log('initial question answer', questionAnswer);
			if (AnswerEvent['checked']) {
				if(questionAnswer === undefined || questionAnswer === null) {
					questionAnswer = [AnswerEvent['source']['value']];
				} else {
					questionAnswer.push(AnswerEvent['source']['value']);
				}
				console.log('added question answer', questionAnswer);
			} else {
				questionAnswer.splice(questionAnswer.findIndex(value => value === AnswerEvent['source']['value']), 1);
				console.log('removed question answer', questionAnswer);
			}
			this.questionList[convertedQuestionIndex].data['questions'][nestedQuestionIndex]['value'] = questionAnswer;
		} else {
			this.questionList[convertedQuestionIndex].data['questions'][nestedQuestionIndex]['value'] = AnswerEvent['source']['value'];
		}
		
		this.saveToLocal();
		this.validateQuestions();
	}

	/** Checks whether the currently selected answer is in the flagged list for the question, 
	 * returning a boolean so that UI elements can be switched on/off as needed. 
	 * 
	 * @param AnswerEvent is the event from the form group
	 * @param questionIndex is the index of the current question being answered
	 * @returns 
	 */
	public isInFlaggedList(answerIndex: number, questionIndex: number): boolean {
		return this.questionList[questionIndex]?.data['flag_values'] !== undefined? 
			this.questionList[questionIndex].data['flag_values'].includes(answerIndex): false;
	}

	/** Updates the accepted filter inputs fro the questionlist
	 * 
	 * @param inputKey is the name of the filter being changed
	 * @param value is the value that needs to be filtered for
	 */
	public updateQuestionFilterInputs(inputKey: string, value: string) {

		this.questionFilterInputs[inputKey] = value;

		if(inputKey === 'tag') {

			const allNewIndex = this.questionFilterInputs[inputKey].findIndex(key => key === 'all');
			const allOldIndex = this.oldTagKeys.findIndex(key => key === 'all');

			if(allNewIndex !== -1 && allOldIndex === -1) {
				this.questionFilterInputs[inputKey] = ['all']
			} else if (this.questionFilterInputs[inputKey].length > 0) {
				this.questionFilterInputs[inputKey] = this.questionFilterInputs[inputKey].filter(key => key !== 'all');
			}
			this.oldTagKeys = [...this.questionFilterInputs[inputKey]];	
		} else if(inputKey === 'state') {

			const allNewIndex = this.questionFilterInputs[inputKey].findIndex(key => key === 'all');
			const allOldIndex = this.oldStateKeys.findIndex(key => key === 'all');

			if(allNewIndex !== -1 && allOldIndex === -1) {
				this.questionFilterInputs[inputKey] = ['all']
			} else if (this.questionFilterInputs[inputKey].length > 0) {
				this.questionFilterInputs[inputKey] = this.questionFilterInputs[inputKey].filter(key => key !== 'all');
			}
			this.oldStateKeys = [...this.questionFilterInputs[inputKey]];
		}

		this.filterQuestions();
	}

	/** Submits the completed assessment to the back end for storage and processing.
	 * 
	 */
	public submitAssessment() {
		const submittedTemplate = Question.rebuildAssessmentTemplate(this.questionList, this.currentPatient, this.currentUuid);
		this.api.submitAssessment(submittedTemplate).subscribe((response) => {
			this.clearLocal();
			this.questionList = [];
			this.shownQuestionList = [];
			this.notes= '';
			this.noteLines = [];
			this.shownNoteLines = [];
			this.cardMode = false;
			this.currentTags= [];
			this.questionFilterInputs = {
				search: undefined,
				type: ['all'],
				tag: ['all'],
				state: ['all']
			};
			this.isDragging = false;
			this.dropContainerIds = [];
			this.currentUuid= '';
			this.currentPatient = '';
			this.allValid = false;
			this.popupShown = false;
			this.oldTagKeys = ['all'];
			this.oldStateKeys = ['all'];
			this.snackBar.open('Assessment Sent', 'close', {duration: 10000});
			this.selectPatient();
		});
	}

	public saveToLocal() {
		localStorage.setItem('questionList', JSON.stringify(this.questionList));
		localStorage.setItem('assessment_uuid', JSON.stringify(this.currentUuid));
		localStorage.setItem('patient_uuid', JSON.stringify(this.currentPatient));
		localStorage.setItem('notes', JSON.stringify(this.notes));
	}

	public loadFromLocal() {
		this.questionList = JSON.parse(localStorage.getItem('questionList'));
		this.currentUuid = JSON.parse(localStorage.getItem('assessment_uuid'));
		this.currentPatient = JSON.parse(localStorage.getItem('patient_uuid'));
		this.notes = JSON.parse(localStorage.getItem('notes'));

		this.shownQuestionList = this.questionList;
		console.log(this.questionList);
		this.updateTags();

		// give a unique ID to the drop container HTML elements so they can have cards assigned to them.
		for (let element of this.shownQuestionList) {
			element.dragId = Math.random()*1000;
			this.dropContainerIds.push(element.dragId.toString());
		};
		this.dropContainerIds.push('noteCards');
	}

	public clearLocal() {
		localStorage.removeItem('questionList');
		localStorage.removeItem('assessment_uuid');
		localStorage.removeItem('patient_uuid');
		localStorage.removeItem('notes');
	}

	public isCheckedAnswer(answerIndex: number, value): boolean {
		if(Array.isArray(value)) {
			const foundIndex = value.findIndex(selectedAnswer => {
				return selectedAnswer === answerIndex;
			});
			return foundIndex !== -1;
		} else {
			return value === answerIndex;
		}
	}
}