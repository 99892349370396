import {v4 as uuidv4} from 'uuid';

export interface QuestionTypeText {
	title: string;
	type: string;
	regex: string;
	value: string;
	flag_values: string[];
}

export interface QuestionTypeSingleChoice {
	title: string;
	type: string;
	items: Array<string | number>;
	value: number;
	flag_values: number[];
}

export interface QuestionTypeYesNo {
	title: string;
	type: string;
	items: Array<string>;
	value: number;
	flag_values: number[];
}

export interface QuestionTypeMultipleChoice {
	title: string;
	type: string;
	items: Array<string | number>;
	value: number[];
	flag_values: number[];
}

export interface QuestionTypeNested {
	title: string;
	type: string;
	questions: object;
}

export interface AssessmentTemplate {
	assessment_uuid: string;
	questions: object[];
	patient_uuid?: string;
}

export class Question {

	// Data contained within the question that is sent to backend
	description: string;
	hint: string;
	required: boolean;
	flagged: boolean;
	notes: string[];
	tags: string[];
	icon: string;
	data: QuestionTypeText | QuestionTypeSingleChoice | QuestionTypeMultipleChoice | QuestionTypeYesNo | QuestionTypeNested;

	// Data that is generated in the front end or used to store state while shown.
	helpExpanded = false;
	questionAnswered = false;
	rawTags: string;
	rawFlags: string;
	notSubmitted = false;
	userFlagged = false;

	fileToUpload: File = null;
	dragId: number;

	constructor() {
	}

	// //https://stackoverflow.com/questions/47936183/angular-file-upload
	// handleFileInput(files: FileList) {
	// 	this.fileToUpload = files.item(0);
	// }

	toggleHelp() {
		this.helpExpanded = !this.helpExpanded;
	}

	markQuestionAnswered() {
		this.questionAnswered = true;
	}

	toggleImportant() {
		this.flagged = !this.flagged;
	}

	/** Takes the list of questions that the user has edited, added, or removed and builds a whole new list in the format the the backend expects.
	 * 
	 * @returns An object that is suitable for transmission to the backend.
	 */
	public static rebuildAssessmentTemplate(questionList: Question[], patientID?: string, uuid?: string): AssessmentTemplate {
		let assessmentTemplate: AssessmentTemplate = {
			assessment_uuid: uuid? uuid : uuidv4(),
			questions: [],
			patient_uuid: patientID
		};

		
		for (const question of questionList) {
			let data = {};

			if(question.data.type == 'SINGLE_CHOICE' && 'items' in question.data) {
				data = {
					type: question.data.type,
					title: question.data.title,
					items: question.data.items ? question.data.items : [],
					value: question.data.value ? question.data.value : null,
					flag_values: question.data.flag_values ? question.data.flag_values : [],
				}
			} else if (question.data.type == 'YES_NO' && 'items' in question.data) {
				data = {
					type: question.data.type,
					title: question.data.title,
					items: question.data.items ? question.data.items : [],
					value: question.data.value ? question.data.value : null,
					flag_values: question.data.flag_values ? question.data.flag_values : [],
				}
			} else if (question.data.type == 'MULTIPLE_CHOICE' && 'items' in question.data) {
				data = {
					type: question.data.type,
					title: question.data.title,
					items: question.data.items ? question.data.items : [],
					value: question.data.value ? question.data.value : [],
					flag_values: question.data.flag_values ? question.data.flag_values : [],
				}
			} else if (question.data.type == 'TEXT' && 'regex' in question.data) {
				data = {
					type: question.data.type,
					title: question.data.title,
					regex: question.data.regex ? question.data.regex : '',
					value: question.data.value ? question.data.value : '',
					flag_values: question.rawFlags ? question.rawFlags.replace(/\s/g,'').split(',') : [],
				}
			} else if (question.data.type == 'NESTED') {
				console.log('nested question: ', question);
				data = {
					type: question.data.type,
					title: question.data.title,
					questions: question.data['questions'] ? question.data['questions']: []
				}
			} else {
				console.error('Question not being sent as valid data type');
				console.error('question ', question, ' data ', data);
			}

			assessmentTemplate.questions.push({
				description: question.description,
				hint: question.hint,
				required: question.required ? true : false,
				flagged: question.flagged ? true : false,
				notes: question.notes ? question.notes : [],
				tags: question.rawTags ? question.rawTags.replace(/\s/g,'').split(',') : [],
				icon: question.icon,
				data: data
			});
		}
		return assessmentTemplate;
	}
}