<div style="margin: 1rem">
	<table mat-table [dataSource]="submittedAssessments" style="width: 100%">

		<!-- Position Column -->
		<ng-container matColumnDef="assessmentID">
			<th mat-header-cell *matHeaderCellDef> Assessment ID </th>
			<td mat-cell *matCellDef="let record"> {{record.assessmentID | slice:-6}} </td>
		</ng-container>
		<ng-container matColumnDef="patientID">
			<th mat-header-cell *matHeaderCellDef> Patient ID </th>
			<td mat-cell *matCellDef="let record"> {{record.patientID | slice:-6}} </td>
		</ng-container>
		<ng-container matColumnDef="assessorID">
			<th mat-header-cell *matHeaderCellDef> Assessor ID </th>
			<td mat-cell *matCellDef="let record"> {{record.assessorID | slice:-6}} </td>
		</ng-container>
		<ng-container matColumnDef="date">
			<th mat-header-cell *matHeaderCellDef> Date </th>
			<td mat-cell *matCellDef="let record"> {{record.date | date}} </td>
		</ng-container>
		<ng-container matColumnDef="action">
			<th mat-header-cell *matHeaderCellDef> Actions </th>
			<td mat-cell *matCellDef="let record">
				<button mat-icon-button (click)="previewAssessment(record)" color="primary"><mat-icon>preview</mat-icon></button>
			</td>
		</ng-container>
	
		<tr mat-header-row *matHeaderRowDef="assessmentColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: assessmentColumns;"></tr>
	</table>
</div>
