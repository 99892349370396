import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AssessmentTemplate } from './assessment/question'
import { Router } from '@angular/router'
import jwt_decode from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	baseUri = `${window.location.origin}/api`
	token: string;
	role: BehaviorSubject<string> = new BehaviorSubject<string>('NO_ROLE');
	questions: object;
	errorStatus: number;
	errorMessage: string;

	constructor(private http: HttpClient, private router: Router) { }

	/** This method is used to log a user in, and if successful a token is passed back to the ApiService which can then be appended onto future calls to protected endpoints.
	 * 
	 * @param username The username you wish to submit for authentication
	 * @param password The password you wish to submit for authentication
	 * @param save an optional boolean denoting whether the token should be saved to local storage or just kept in memory, by default it is not saved
	 * 
	 * @returns A boolean observable the is true if a value was returned and assigned and false otherwise.
	 */
	public getTokenObservable(username: string, password: string, save?: boolean): Observable<boolean> {
		this.clearErrors();

		console.log(this.role);

		return new Observable<boolean>((observer) => {

			// Sets up the header in the format expected by the backend, it's using Basic Authorization
			let headers = new HttpHeaders();
			headers = headers.append("Authorization", `Basic ${btoa(username + ':' + password)}`);

			// Posts the data to the backend, checks for errors being returned, and saves the token if there are no errors.
			this.http.post(this.baseUri + '/auth/login', null, {
				observe: 'body', 
				responseType: 'json', 
				headers: headers
			}).subscribe({
				next: (response) => {
					this.token = response['api_key'];
					// Checks if a token was received and returns the appropriate value while also saving it to local storage if true.
					if(this.token) {
						const decodedToken = jwt_decode(this.token);
						this.role.next(decodedToken['role']);
						if(save) {
							localStorage.setItem('authToken', this.token);
							localStorage.setItem('role', decodedToken['role']);
						}
						observer.next(true);
						observer.complete();
					}
				},
				error: (message) => {
					this.handleError(message);
					observer.next(false);
					observer.complete();
				}
			});
		});
	}

	/** This method clears the auth token from memory and local storage, logging a user out of the system */
	public clearAuthToken() {
		this.token = undefined;
		this.role.next('NO_ROLE');
		localStorage.removeItem('authToken');
		localStorage.removeItem('role');
	}

	private backendGet(endpoint: string): Observable<object> {
		this.clearErrors();

		return new Observable<object>((observer) => {

			let headers = new HttpHeaders();
			headers = headers.append("X-API-Key", this.getToken());

			this.http.get(this.baseUri + endpoint, {
				observe: 'body', 
				responseType: 'json', 
				headers: headers
			}).subscribe({
				next: (response) => {
					observer.next(response);
					observer.complete();
				},
				error: (message) => {
					this.handleError(message);
					observer.error(new Error(message));
					observer.complete();
				}
			});
		});
	}

	private backendPatch(endpoint: string, body: object): Observable<boolean> {
		this.clearErrors();

		return new Observable<boolean>((observer) => {

			let headers = new HttpHeaders();
			headers = headers.append("X-API-Key", this.getToken());

			this.http.patch(this.baseUri + endpoint, body, {
					observe: 'body', 
					responseType: 'json', 
					headers: headers
			}).subscribe({
				next: () => {
					observer.next(true);
					observer.complete();
				},
				error: (message) => {
					this.handleError(message);
					observer.error(new Error(message));
					observer.complete();
				}
			});
		});
	}

	private backendPost(endpoint: string, body: object): Observable<boolean> {
		this.clearErrors();

		return new Observable<boolean>((observer) => {

			let headers = new HttpHeaders();
			headers = headers.append("X-API-Key", this.getToken());

			console.log('sending this: ', body);

			this.http.post(this.baseUri + endpoint, body, {
					observe: 'body', 
					responseType: 'json', 
					headers: headers
			}).subscribe({
				next: () => {
					observer.next(true);
					observer.complete();
				},
				error: (message) => {
					this.handleError(message);
					observer.error(new Error(message));
					observer.complete();
				}
			});
		});
	}

	private backendDelete(endpoint: string): Observable<boolean> {
		this.clearErrors();

		return new Observable<boolean>((observer) => {

			let headers = new HttpHeaders();
			headers = headers.append("X-API-Key", this.getToken());

			this.http.delete(this.baseUri + endpoint, {
					observe: 'body', 
					responseType: 'json', 
					headers: headers
			}).subscribe({
				next: () => {
					observer.next(true);
					observer.complete();
				},
				error: (message) => {
					this.handleError(message);
					observer.error(new Error(message));
					observer.complete();
				}
			});
		});
	}

	/** This method gets a observable that returns the list of questions from the backend and then marks itself as complete.
	 * @returns an observable of the question object. 
	 */
	public getQuestionsObservable(): Observable<object> {
		return this.backendGet('/assessments/latest');
	}

	/** This method posts the AssessmentTemplate to the server
	 * @returns an observable with a boolean that is true if it was successful
	 */
	public submitQuestions(template: AssessmentTemplate): Observable<boolean> {
		console.log('sending this', template)
		return this.backendPatch('/assessment', template);
	}

	/** This method posts the completed AssessmentTemplate to the server
	 * @returns an observable with a boolean that is true if it was successful
	 */
	 public submitAssessment(template: AssessmentTemplate): Observable<boolean> {
		console.log('sending this', template)
		return this.backendPost('/assessment/submit', template);
	}

	/** This method gets all of the submissions that the user is able to access.
	 * @returns an observable with a boolean that is true if it was successful
	 */
	 public getAssessments(): Observable<object> {
		return this.backendGet('/assessment_submissions');
	}

	/** This method gets all of the users that the user is able to access.
	 * @returns an observable with a boolean that is true if it was successful
	 */
	 public getUsers(): Observable<object> {
		return this.backendGet('/users');
	}

	/** This method gets all of the patients that the user is able to access.
	 * @returns an observable with a boolean that is true if it was successful
	 */
	 public getPatients(): Observable<object> {
		return this.backendGet('/patients');
	}

	/** This method gets the data for the selected user
	 * @returns an observable with a boolean that is true if it was successful
	 */
	 public getPatient(uuid: string): Observable<object> {
		return this.backendGet('/patients/' + uuid);
	}

	/** This method posts a new user to the backend.
	 * @returns an observable with a boolean that is true if it was successful
	 */
	public submitUser(user: object): Observable<boolean> {
		return this.backendPost('/user', user);
	}

	/** This method posts a new user to the backend.
	 * @returns an observable with a boolean that is true if it was successful
	 */
	public patchUser(user: object, uuid: string): Observable<boolean> {
		return this.backendPatch('/users/' + uuid, user);
	}

	/** This method posts a new patient to the backend.
	 * @returns an observable with a boolean that is true if it was successful
	 */
	public submitPatient(patient: object): Observable<boolean> {
		return this.backendPost('/patient', patient);
	}

	/** This method posts a new patient to the backend.
	 * @returns an observable with a boolean that is true if it was successful
	 */
	 public patchPatient(patient: object, uuid: string): Observable<boolean> {
		return this.backendPatch('/patients/' + uuid, patient);
	}

	/** This method gets the data for the selected user
	 * @returns an observable with a boolean that is true if it was successful
	 */
	public getUser(uuid: string): Observable<object> {
		return this.backendGet('/users/' + uuid);
	}

	/** This method gets the data for the selected user
	 * @returns an observable with a boolean that is true if it was successful
	 */
	 public deleteUser(uuid: string): Observable<boolean> {
		return this.backendDelete('/users/' + uuid);
	}

	/** This method takes in errors and formats them for display to the console and end user.
	 *  This is based on the Angular documentation here https://angular.io/api/common/http/HttpErrorResponse#description
	 * 
	 * @param error is the error that has been returned from a request to the backend.
	 * 
	 * @returns an observable with a user facing error message.
	 */
	private handleError(error: HttpErrorResponse) {
		this.errorStatus = error.status;
		this.errorMessage = error.message;

		// If the error is that the user is unauthorized clear their token and take them back to the login screen. 
		if(error.status === 401) {
			this.clearAuthToken();
			this.router.navigate(['/login']);
		}

		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: `, error.error);
		}
		// Return an observable with a user-facing error message.
		return throwError(
		  'Something bad happened; please try again later.');
	}

	private clearErrors() {
		this.errorStatus = undefined;
		this.errorMessage = undefined;
	}

	private getToken(): string {
		let token;
		if(this.token) {
			token = this.token;
		} else {
			token = localStorage.getItem('authToken');
			const decodedToken = jwt_decode(token);
			this.role.next(decodedToken['role']);
		}
		return token;
	}
}
