import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Patient {
	assigned_patients: string[];
	created: string;
	email: string;
	employee_number: string;
	last_updated: string;
	role: string;
	uuid: string;
}

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css']
})

export class PatientsComponent implements OnInit {

	public patients: BehaviorSubject<Patient[]> = new BehaviorSubject<Patient[]>([]);

	public patientColumns: string[] = ['patientID', 'firstName', 'lastName', 'dateOfBirth', 'action'];

	constructor(public dialog: MatDialog, private api: ApiService) { }

	ngOnInit(): void {
		this.getPatients();
	}

	public getPatients() {
		this.api.getPatients().subscribe(
			(response) => {
				let patients = [];
				for(const patient of response as Array<object>[]) {
					patients.push({
						patientUuid: patient['uuid'],
						patientID: patient['patient_number'],
						firstName: patient['firstname'],
						lastName: patient['lastname'],
						dateOfBirth: patient['dateofbirth']
					});
				}
				this.patients.next(patients);
				console.log('patients: ', this.patients.value);
			},
			(error) => {
				console.log(error);
			}
		)
	}

	public editPatient(record: object) {
		const dialogRef = this.dialog.open(PatientPopup, {data: {uuid: record['patientUuid']}});

		dialogRef.afterClosed().subscribe(result => {
			this.getPatients();
		});
	}

	public newPatient() {
		const dialogRef = this.dialog.open(PatientPopup);

		dialogRef.afterClosed().subscribe(result => {
			this.getPatients();
		});
	}
}

@Component({
	selector: 'app-patientPopup',
	template: `
	<mat-dialog-content>
		<form [formGroup]="patientGroup">
			<h2>Patient Details</h2>
			<mat-form-field appearance="outline" required>
				<mat-label>First Name</mat-label>
				<input matInput placeholder="John" formControlName="firstName">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline" required>
				<mat-label>Last Name</mat-label>
				<input matInput placeholder="Smith" formControlName="lastName">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline" required>
				<mat-label>Date of Birth</mat-label>
				<input matInput [matDatepicker]="picker" formControlName="dateOfBirth">
				<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
				<mat-datepicker #picker startView="multi-year" startAt="1960"></mat-datepicker>
			</mat-form-field>
		</form>

		<hr>
		<div style="display: flex; flex-flow: row nowrap; align-items: center;">
			<h2 style="margin: 0">Emergency Contacts</h2>
			<button mat-icon-button (click)="addContact('EMERGENCY')" color="primary"><mat-icon>person_add</mat-icon></button>
		</div>
		
		<div *ngFor="let contact of emergencyContacts; let i=index">
			<div style="display: flex; flex-flow: row nowrap; align-items: center;">
				<h3 style="margin: 0">Emergency Contact {{i+1}}</h3>
				<button mat-icon-button (click)="deleteContact(i, 'EMERGENCY')" color="primary"><mat-icon>remove_circle</mat-icon></button>
			</div>
			
			<mat-form-field appearance="outline">
				<mat-label>First Name</mat-label>
				<input matInput [(ngModel)]="contact.firstName" placeholder="John">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline">
				<mat-label>Last Name</mat-label>
				<input matInput [(ngModel)]="contact.lastName" placeholder="Smith">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline">
				<mat-label>Relationship</mat-label>
				<input matInput [(ngModel)]="contact.relationship" placeholder="Spouse">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline">
				<mat-label>Contact Number</mat-label>
				<input matInput [(ngModel)]="contact.contactNumber" placeholder="(02) 9876 5432">
			</mat-form-field>
		</div>

		<hr>
		<div style="display: flex; flex-flow: row nowrap; align-items: center;">
			<h2 style="margin: 0">HealthCare Contacts</h2>
			<button mat-icon-button (click)="addContact('HEALTHCARE')" color="primary"><mat-icon>person_add</mat-icon></button>
		</div>
		
		<ng-container *ngFor="let contact of healthCareContacts; let i=index">
			<div style="display: flex; flex-flow: row nowrap; align-items: center;">
				<h3 style="margin: 0">HealthCare Contact {{i+1}}</h3>
				<button mat-icon-button (click)="deleteContact(i, 'HEALTHCARE')" color="primary"><mat-icon>remove_circle</mat-icon></button>
			</div>
			
			<mat-form-field appearance="outline">
				<mat-label>Title</mat-label>
				<input matInput [(ngModel)]="contact.title" placeholder="Doctor">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline">
				<mat-label>Last Name</mat-label>
				<input matInput [(ngModel)]="contact.lastName" placeholder="Smith">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline">
				<mat-label>Contact Number</mat-label>
				<input matInput [(ngModel)]="contact.contactNumber" placeholder="(02) 9876 5432">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline">
				<mat-label>Address</mat-label>
				<input matInput [(ngModel)]="contact.address" placeholder="123 Fake St, Sydney, NSW, 2000">
			</mat-form-field>
		</ng-container>
		<br>
		<button mat-raised-button *ngIf="!this.uuid" color="primary" (click)="createPatient()" [disabled]="!patientGroup.valid">Create New Patient</button>
		<button mat-raised-button *ngIf="this.uuid" color="primary" (click)="patchPatient()">Submit Changes</button>
	</mat-dialog-content>
	`
})
export class PatientPopup {

	public emergencyContacts = [];
	public healthCareContacts = [];
	uuid: string = undefined;

	patientGroup = new FormGroup({
		firstName: new FormControl('', Validators.required),
		lastName: new FormControl('', Validators.required),
		dateOfBirth: new FormControl('', Validators.required)
	});

	constructor(private api: ApiService, public dialogRef: MatDialogRef<PatientPopup>, @Inject(MAT_DIALOG_DATA) public data: any) {
		this.uuid = data?.uuid;
		if(this.uuid !== undefined) {
			this.api.getPatient(this.uuid).subscribe(
				(result) => {
					this.patientGroup.setValue({firstName: result['firstname'], lastName: result['lastname'], dateOfBirth: result['dateofbirth']});
					for(const contact of result['emergency_contacts']) {
						this.emergencyContacts.push({
							firstName: contact.firstname,
							lastName: contact.lastname,
							relationship: contact.relationship,
							contactNumber: contact.contact_number
						})
					}

					for(const contact of result['healthcare_contacts']) {
						this.healthCareContacts.push({
							title: contact.title,
							lastName: contact.lastname,
							address: contact.address,
							contactNumber: contact.contact_number
						})
					}
				},
				(error) => {
					console.log(error);
				}
			);
		}
	}

	createPatient() {
		let emergencyContactList = [];

		for(const contact of this.emergencyContacts) {
			emergencyContactList.push({
				firstname:  contact['firstName'],
				lastname: contact['lastName'],
				relationship: contact['relationship'],
				contact_number: contact['contactNumber'],
			})
		}

		let healthcareContactList = [];

		console.log(this.healthCareContacts);

		for(const contact of this.healthCareContacts) {
			healthcareContactList.push({
				title:  contact['title'],
				lastname: contact['lastName'],
				contact_number: contact['contactNumber'],
				address: contact['address'],
			})
		}
		
		const patient = {
			firstname: this.patientGroup.value['firstName'],
			lastname: this.patientGroup.value['lastName'],
			dateofbirth: this.patientGroup.value['dateOfBirth'],
			emergency_contacts: emergencyContactList,
			healthcare_contacts: healthcareContactList
		}

		this.api.submitPatient(patient).subscribe(
			() => {
				this.dialogRef.close();
			},
			(error) => {
				console.log(error);
			}
		);
	}

	addContact(type: string) {
		if(type === 'EMERGENCY') {
			this.emergencyContacts.push({
				firstName: '',
				lastName: '',
				relationship: '',
				contactNumber: '',
			});
		} else if(type === 'HEALTHCARE') {
			this.healthCareContacts.push({
				title: '',
				lastName: '',
				contactNumber: '',
				address: '',
			})
		}
		
	}

	deleteContact(index: number, type: string) {
		if(type === 'EMERGENCY') {
			this.emergencyContacts.splice(index, 1);
		} else if (type === 'HEALTHCARE') {
			this.healthCareContacts.splice(index, 1);
		}
	}

	patchPatient() {
		let emergencyContactList = [];

		for(const contact of this.emergencyContacts) {
			emergencyContactList.push({
				firstname:  contact['firstName'],
				lastname: contact['lastName'],
				relationship: contact['relationship'],
				contact_number: contact['contactNumber'],
			})
		}

		let healthcareContactList = [];

		console.log(this.healthCareContacts);

		for(const contact of this.healthCareContacts) {
			healthcareContactList.push({
				title:  contact['title'],
				lastname: contact['lastName'],
				contact_number: contact['contactNumber'],
				address: contact['address'],
			})
		}
		
		const patient = {
			firstname: this.patientGroup.value['firstName'],
			lastname: this.patientGroup.value['lastName'],
			dateofbirth: this.patientGroup.value['dateOfBirth'],
			emergency_contacts: emergencyContactList,
			healthcare_contacts: healthcareContactList
		}

		this.api.patchPatient(patient, this.uuid).subscribe(
			() => {
				this.dialogRef.close();
			},
			(error) => {
				console.log(error);
			}
		);
	}
}

