import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { ApiService } from './api.service';

export interface TokenResponse {
	"uuid": string,
	"created": string,
	"last_updated": string,
	"token": string,
	"revoked": boolean,
	"user_uuid": string
}

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	title = 'Hope Holistic Care - Online Assessment Tool';
	errorToDisplay = false;
	role: string;
	
	constructor(private router: Router, private api: ApiService) { }

	ngOnInit() {
		this.api.role.subscribe({
			next: (response) => {
				this.role = response;
			}});
	}

	public hasRoute(route: string) {
		return this.router.url.includes(route);
	}

	public logout() {
		this.api.clearAuthToken();
		this.router.navigate(['/login']);
	}
}