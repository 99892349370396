import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../api.service';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface User {
	assigned_patients: string[];
	created: string;
	email: string;
	employee_number: string;
	last_updated: string;
	role: string;
	uuid: string;
}

@Component({
	selector: 'app-users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

	public users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
	public userColumns: string[] = ['employee_number', 'email', 'role', 'last_updated', 'action'];

	constructor(public dialog: MatDialog, private api: ApiService) { }

	ngOnInit(): void {
		this.getUsers();
	}

	public getUsers() {
		this.api.getUsers().subscribe(
			(response) => {
				let userArray = [];
				for(const user of response as Array<object>[]) {
					userArray.push(user);
				}
				this.users.next(userArray);
			},
			(error) => {
				console.log(error);
			}
		)
	}

	public editUser(record: object) {
		this.dialog.open(UserPopup, {data: {uuid: record['uuid']}});
	}

	public newUser() {
		const dialogRef = this.dialog.open(UserPopup);

		dialogRef.afterClosed().subscribe(() => {
			this.getUsers();
		});
	}

	public deleteUser(record: object) {
		const dialogRef = this.dialog.open(DeletePopup, {data: {uuid: record['uuid']}});

		dialogRef.afterClosed().subscribe(() => {
			this.getUsers();
		});
	}
}

@Component({
	selector: 'app-userPopup',
	template: `
	<mat-dialog-content>
		<h2>{{employeeNumber}}</h2>
		<form [formGroup]="userGroup">
			<mat-form-field appearance="outline" required>
				<mat-label>Email</mat-label>
				<input matInput placeholder="someone@example.com" formControlName="username">
			</mat-form-field>
			<br>
			<button *ngIf="uuid && !changePassword" mat-raised-button color="primary" (click)="changePassword = true" style="margin-bottom: 1rem">Change Password</button>
			<mat-form-field *ngIf="!uuid || changePassword" appearance="outline" required>
				<mat-label>Password</mat-label>
				<input matInput type="Password" formControlName="password">
			</mat-form-field>
			<br>
			<mat-form-field appearance="outline" required>
				<mat-select formControlName="role">
					<mat-option value="ASSESSOR">Assessor</mat-option>
					<mat-option value="ADMIN">Admin</mat-option>
				</mat-select>
				<mat-label>Role</mat-label>
			</mat-form-field>
			<ng-container *ngIf="this.uuid && userGroup.value['role'] === 'ASSESSOR'">
				<h2 style="margin: 0">Assigned Patients</h2>
				<mat-form-field appearance="outline">
					<mat-label>Patients</mat-label>
					<mat-select multiple (selectionChange)="assignedPatients = $event.value" [value]="assignedPatients">
						<mat-option *ngFor="let patient of currentPatients;" value={{patient}}>{{patient | slice:-6}}</mat-option>
					</mat-select>
				</mat-form-field>
			</ng-container>
		</form>
		<br>
		<button *ngIf="!this.uuid" mat-raised-button color="primary" (click)="createUser()" [disabled]="!userGroup.valid">Create New User</button>
		<button *ngIf="this.uuid" mat-raised-button color="primary" (click)="patchUser()">Apply Changes</button>
	</mat-dialog-content>
	`
})
export class UserPopup {

	assignedPatients = [];
	currentPatients = [];
	uuid: string = undefined;
	changePassword = false;
	employeeNumber: string;

	userGroup = new FormGroup({
		username: new FormControl('', Validators.required),
		password: new FormControl('', Validators.required),
		role: new FormControl('', Validators.required),
	});

	user = {
		email: this.userGroup.value['username'],
		role: this.userGroup.value['role'],
		assigned_patients: this.assignedPatients,
		password: this.userGroup.value['password']
	}

	constructor(private api: ApiService, public dialogRef: MatDialogRef<UserPopup>, @Inject(MAT_DIALOG_DATA) public data: any) {
		this.api.getPatients().subscribe(
			(result) => {
				const patientList = result as Array<object>;
				for(const patient of patientList) {
					this.currentPatients.push(patient['uuid']);
				}
			},
			(error) => {
				console.log(error);
			}
		);
		this.uuid = data?.uuid;
		if(this.uuid !== undefined) {
			this.api.getUser(this.uuid).subscribe(
				(result) => {
					this.userGroup.setValue({username: result['email'], role: result['role'], password: ''});
					this.assignedPatients = result['assigned_patients'];
					this.employeeNumber = result['employee_number']
				},
				(error) => {
					console.log(error);
				}
			);
		}
	}

	createUser() {
		const user = {
			email: this.userGroup.value['username'],
			role: this.userGroup.value['role'],
			assigned_patients: [],
			password: this.userGroup.value['password']
		}
		
		this.api.submitUser(user).subscribe(
			() => {
				this.dialogRef.close();
			},
			(error) => {
				console.log(error);
			}
		);
	}

	patchUser() {
		console.log(this.uuid);
		const user = {
			email: this.userGroup.value['username'],
			role: this.userGroup.value['role'],
			assigned_patients: this.assignedPatients,
			password: this.userGroup.value['password']
		}

		if(!this.changePassword) {
			delete user.password;
		}

		this.api.patchUser(user, this.uuid).subscribe(
			() => {
				this.dialogRef.close();
			},
			(error) => {
				console.log(error);
			}
		);
	}
}

@Component({
	selector: 'app-deletePopup',
	template: `
	<mat-dialog-content style="display:flex; flex-flow: column nowrap; align-items: center; justify-content: center;">
		<h2>Are you sure you want to delete this user?</h2>
		This action cannot be undone.
		<button mat-raised-button color="primary" (click)="deleteUser()" style="margin-top: 1rem">Confirm Delete</button>
	</mat-dialog-content>
	`
})
export class DeletePopup {

	constructor(private api: ApiService, public dialogRef: MatDialogRef<UserPopup>, @Inject(MAT_DIALOG_DATA) public data: any) {
		
	}

	deleteUser() {
		this.api.deleteUser(this.data?.uuid).subscribe(
			() => {
				this.dialogRef.close();
			},
			(error) => {
				console.log(error);
			}
		);
	}
}
